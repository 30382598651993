import {
  AWSMPCustomer,
  AuthUser,
  Corpus,
  Customer,
  CustomerStatus,
  StatsModels,
  FailedDoc,
  FaissServer,
  Plan,
  PromptConfigParams,
  ScalePlanDefaults,
  Stats,
  PlanType,
  PricingPlanDetails
} from "../utils/commonTypes";
import { handleErrors } from "../utils/errorHandler";
import { removeAuthTokenFromStorage } from "../utils/storage";

const API_BACKEND = `${process.env.REACT_APP_API_ENDPOINT}`;

export const listCustomers = async (authToken: string): Promise<Array<Customer>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({})
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const customers = jsonData.customers.map((item: Record<string, unknown>) => {
    const itemObj = JSON.parse(item.toString());
    return {
      customerId: itemObj["customerId"],
      userName: itemObj["name"],
      userEmail: itemObj["root_email"],
      status: CustomerStatus.ACT,
      planName: itemObj["planName"]
    };
  });
  return customers;
};

export const deletedCustomers = async (authToken: string): Promise<Array<Customer>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/deleted-customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({})
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const customers = jsonData.customers.map((item: Record<string, unknown>) => {
    const itemObj = JSON.parse(item.toString());
    return {
      customerId: itemObj["customerId"],
      userName: itemObj["name"],
      userEmail: itemObj["root_email"],
      status: CustomerStatus.ACT
    };
  });
  return customers;
};

export const listCorpora = async (customerId: number, authToken: string): Promise<Array<Corpus>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-corpora`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const corpora = jsonData.corpora.map((item: Record<string, unknown>) => {
    const itemObj = JSON.parse(item.toString());
    return { corpusId: itemObj["corpusId"], corpusName: itemObj["name"], enabled: itemObj["enabled"] };
  });
  return corpora;
};

export const listJobs = async (customerId: number, authToken: string) => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-jobs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const jobs = jsonData.jobs.map((job: Record<string, unknown>) => {
    const jobObj = JSON.parse(job.toString());
    return {
      jobId: jobObj["jobId"],
      typeId: jobObj["typeId"],
      corpusId: jobObj["corpusId"],
      description: jobObj["desc"],
      params: jobObj["params"],
      status: jobObj["status"],
      comment: jobObj["comment"]
    };
  });
  return jobs;
};

export const readPricingPlans = async (customerId: number, authToken: string): Promise<Array<Plan>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/read-pricing-plan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const plans = jsonData.plans.map((item: Record<string, any>): Plan => {
    const itemObj = JSON.parse(item.toString());
    const planObj = {
      planId: itemObj.planId,
      planName: itemObj.planName,
      planDescription: itemObj.description,
      planJson: itemObj.planJson,
      startDate: itemObj.dtEffective,
      expiryDate: itemObj.dtExpire,
      planType: itemObj.planType
    };
    return planObj;
  });

  return plans;
};

export const deleteCustomer = async (customerId: number, authToken: string): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/delete-customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  return true;
};

export const purgeCustomer = async (customerId: number, authToken: string): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/purge-customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  return true;
};

export const readCustomerStats = async (
  customerId: number,
  authToken: string,
  numMonths: number,
  anchorBased: boolean
): Promise<Array<Stats>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/read-customer-stats`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId, "no-of-months": numMonths, "anchor-based": anchorBased })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const stats = jsonData.stats.map((stat: Record<string, unknown>): Stats => {
    const statsObj = JSON.parse(stat.toString());

    return {
      queryCount: statsObj.queries,
      summarizations: statsObj.modelSummarizationCount[StatsModels.OTHERS] ?? 0,
      gpt4GenerativeRequests: statsObj.modelSummarizationCount[StatsModels.GPT_4] ?? 0,
      bytesIngested: statsObj.bytes,
      storageUsed: statsObj.storage,
      users: statsObj.users,
      corpora: statsObj.corpora,
      month: statsObj.month,
      year: statsObj.year,
      startEpoch: statsObj.startEpochSecs,
      endEpoch: statsObj.endEpochSecs
    };
  });
  return stats;
};

export const switchCustomerPlan = async (
  customerId: number,
  authToken: string,
  newPlanId: string,
  planDefaults: ScalePlanDefaults,
  faissServerId: number,
  upgrade: boolean
): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/switch-customer-plan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      "customer-id": customerId,
      upgrade: upgrade,
      "plan-id": newPlanId,
      "faiss-server-id": faissServerId,
      "env-settings": {
        "max-corpora": planDefaults.maxCorpora,
        "max-result-rows": planDefaults.maxResultRows,
        "max-rerank-rows": planDefaults.maxRerankRows,
        "max-bytes": planDefaults.maxBytes
      }
    })
  });
  if (response.status !== 200) {
    const detail = await response.text();
    handleErrors(response.status, detail);
    throw new Error(detail);
  }
  return true;
};

export const authorizeUser = async (idTokenString: string): Promise<AuthUser> => {
  const response = await fetch(`${API_BACKEND}/read-user-authorization`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ idTokenString: idTokenString })
  });
  if (response.status !== 200) {
    // Failed to authenticate
    removeAuthTokenFromStorage();
    console.log(response);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  return {
    email: jsonData["username"],
    role: jsonData["role"],
    permissions: jsonData.permissions
  };
};

export const listEncoders = async (customerId: number, authToken: string) => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-encoders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const encoders = jsonData.encoders.map((encoder: Record<string, unknown>) => {
    const encoderObj = JSON.parse(encoder.toString());
    return {
      name: encoderObj["name"],
      description: encoderObj["description"],
      dimension: encoderObj["dimension"],
      cxSpec: encoderObj["cxSpec"],
      cxSpecServing: encoderObj["cxSpecServing"],
      default: encoderObj["defaultEncoder"]
    };
  });
  return encoders;
};

export const createEncoder = async (
  customerId: number,
  authToken: string,
  name: string,
  description: string,
  dimension: number,
  host: string,
  port: number
): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/create-encoder`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      "customer-id": customerId,
      name: name,
      description: description,
      dimension: dimension,
      host: host,
      port: port
    })
  });
  if (response.status !== 200) {
    const detail = await response.text();
    handleErrors(response.status, detail);
    throw new Error(detail);
  }
  return true;
};

export const listPrompts = async (customerId: number, authToken: string) => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-prompts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const prompts = jsonData.prompts.map((prompt: Record<string, unknown>) => {
    const promptObj = JSON.parse(prompt.toString());
    const modelDef = promptObj["modelDef"];
    const promptDef = promptObj["summarizationPromptDef"];
    return {
      name: promptDef["name"],
      description: promptDef["description"],
      model: { modelId: modelDef["id"]["value"], modelName: modelDef["externalName"] },
      prompt: promptDef["prompt"],
      configParams: promptDef["configParams"],
      isDefault: promptDef["isDefault"]
    };
  });
  return prompts;
};

export const createPrompt = async (
  customerId: number,
  authToken: string,
  name: string,
  description: string,
  modelId: number,
  modelName: string,
  promptType: string,
  prompt: string,
  testOnly: boolean,
  configParams: PromptConfigParams
): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/create-prompt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      customerId: customerId,
      name: name,
      description: description,
      modelId: modelId,
      modelName: modelName,
      promptType: promptType,
      prompt: prompt,
      testOnly: testOnly,
      configParams: JSON.stringify(configParams)
    })
  });
  if (response.status !== 200) {
    const detail = await response.text();
    handleErrors(response.status, detail);
    throw new Error(detail);
  }
  return true;
};

export const listAWSMPCustomers = async (authToken: string): Promise<Array<AWSMPCustomer>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-awsmp-customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({})
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const customers = jsonData.customers.map((item: Record<string, unknown>): AWSMPCustomer => {
    const itemObj = JSON.parse(item.toString());
    return {
      customerId: itemObj["customerId"],
      name: itemObj["name"],
      userEmail: itemObj["root_email"],
      awsId: itemObj["awsId"],
      awsAccountId: itemObj["awsAccountId"],
      tsContractStart: itemObj["tsContractStart"],
      tsContractExpire: itemObj["tsContractExpire"],
      awsProductCode: itemObj["awsProductCode"],
      planId: itemObj["customerPlanId"],
      planName: itemObj["customerPlanName"]
    };
  });
  return customers;
};

export const listFailedDocs = async (
  authToken: string,
  customerId: number,
  limit: number,
  documentId: string
): Promise<Array<FailedDoc>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-failed-docs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({ "customer-id": customerId, limit: limit, "document-id": documentId })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const failedDocs = jsonData.failedDocs.map((item: Record<string, unknown>): FailedDoc => {
    const itemObj = JSON.parse(item.toString());
    const locationObj = JSON.parse(itemObj.location.toString());
    return {
      id: itemObj.id,
      documentId: itemObj.documentId,
      customerId: itemObj.customerCorpusKey.customerId,
      corpusId: itemObj.customerCorpusKey.corpusId,
      originalStatusCode: itemObj.originalStatus.code_,
      originalStatusText: itemObj.originalStatus.statusDetail_,
      mimeType: itemObj.mimeType,
      locationType: locationObj.type,
      locationPath: locationObj.path,
      locationBucket: locationObj.bucket,
      api: itemObj.api
    };
  });
  return failedDocs;
};

export const downloadFailedDoc = async (authToken: string, failedDoc: FailedDoc): Promise<boolean> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/download-failed-doc`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      id: failedDoc.id,
      docId: failedDoc.documentId,
      location: failedDoc.locationPath,
      bucket: failedDoc.locationBucket,
      customerId: failedDoc.customerId,
      corpusId: failedDoc.corpusId
    })
  });
  if (response.status !== 200) {
    const detail = await response.text();
    handleErrors(response.status, detail);
    throw new Error(detail);
  }
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = failedDoc.documentId + ".tar";
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
  return true;
};

export const readAvailableFaissServers = async (authToken: string): Promise<Array<FaissServer>> => {
  if (authToken === "") throw new Error("No auth token provided");
  const response = await fetch(`${API_BACKEND}/list-faiss-servers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({})
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();
  const servers = jsonData.servers.map((item: Record<string, unknown>) => {
    const itemObj = JSON.parse(item.toString());
    return {
      id: itemObj["id"],
      host: itemObj["host"],
      labels: itemObj["labels"],
      capacityGB: itemObj["capacityGB"],
      reservedGB: itemObj["reservedGB"],
      availableGB: itemObj["availableGB"],
      customersCount: itemObj["customers"]
    };
  });
  return servers;
};

export const searchCustomers = async (authToken: string, searchTerm: string): Promise<Array<Customer>> => {
  if (authToken === "") throw new Error("No auth token provided");

  const response = await fetch(`${API_BACKEND}/search-customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      "operational-statuses": [CustomerStatus.ACT, CustomerStatus.EXP],
      "search-term": searchTerm
    })
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
  const jsonData = await response.json();

  const customers = jsonData.customers.map((item: Record<string, unknown>) => {
    return {
      customerId: item.customerId,
      userName: item.name,
      userEmail: item.root_email,
      status: CustomerStatus[item.operationalStatus as CustomerStatus]
    };
  });

  return customers;
};

export const extendCustomerFreeTrial = async (
  authToken: string,
  customerId: number,
  expiryEpochSecond: number,
  planSpec: string
) => {
  if (authToken === "") throw new Error("No auth token provided");

  const response = await fetch(`${API_BACKEND}/extend-customer-free-trial`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      "customer-id": customerId,
      "expiry-epoch-second": expiryEpochSecond,
      "plan-spec": planSpec
    })
  });

  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }
};

export const readPricingPlanDetails = async (
  authToken: string,
  planName: PlanType,
  customerId: number
): Promise<PricingPlanDetails> => {
  if (authToken === "") throw new Error("No auth token provided");

  const response = await fetch(`${API_BACKEND}/read-pricing-plan-details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken
    },
    body: JSON.stringify({
      "plan-name": planName,
      "customer-id": customerId
    })
  });

  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }

  const responseJson = await response.json();
  return { ...responseJson, planSpec: JSON.parse(responseJson.planSpec) };
};
