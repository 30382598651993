export type Customer = {
  customerId: number;
  userEmail: string;
  userName: string;
  status: CustomerStatus;
};

export enum CustomerStatus {
  ACT = "ACT",
  MDEL = "MDEL",
  DEL = "DEL",
  EXP = "EXP"
}

export type Corpus = {
  corpusId: number;
  enabled: boolean;
  corpusName: string;
};

export type Job = {
  jobId: number;
  typeId: number;
  corpusId: number;
  description: string;
  params: string;
  status: string;
  comment: string;
};

export type Plan = {
  planId: string;
  planName: string;
  planDescription: string;
  planJson: string;
  startDate: number;
  expiryDate: number;
  planType?: PlanType;
};

export enum PlanType {
  PLAN_NAME__FREE_TRIAL = "PLAN_NAME__FREE_TRIAL"
}

export type AuthUser = {
  email: string;
  role: string;
  permissions: Array<string>;
};

export type Stats = {
  queryCount: number;
  summarizations: number;
  gpt4GenerativeRequests: number;
  bytesIngested: number;
  storageUsed: number;
  users: number;
  corpora: number;
  month: string;
  year: number;
  startEpoch: number;
  endEpoch: number;
};

export enum StatsModels {
  GPT_4 = "gpt-4",
  OTHERS = "*"
}

export type ScaleCustomer = {
  id: number;
  name: string;
};

export enum FeatureType {
  FEATURE__UNKNOWN = 0,
  FEATURE__RECEIVE_SCORE = 1,
  FEATURE__QUERY_BG_VEC = 2,
  FEATURE__CUSTOM_DIMENSIONS = 3,
  FEATURE__ENCODER_SWAPPING = 4,
  FEATURE__TEXTLESS = 5,
  FEATURE__USER_RATE_LIMIT = 6,
  FEATURE__CORPUS_RATE_LIMIT = 7,
  FEATURE__CORPUS_ENCRYPTION_KEY = 8,
  FEATURE__CUSTOMER_MANAGED_ENCRYPTION_KEY = 9,
  FEATURE__DOCUMENT_METADATA = 10,
  FEATURE__DOCUMENT_PART_METADATA = 11,
  FEATURE__PREDICATE_FILTERING = 12
}

export type PricingPlanPeriod = {
  years: number;
  months: number;
  days: number;
};

export type PricingPlanPrice = {
  amount: number;
  currency: string;
};

export type PricingPlanFeature = {
  feature: keyof typeof FeatureType;
};

export type PricingPlan = {
  enabledFeatures: Array<PricingPlanFeature>;
  includedQueries: {
    amount: number;
    period: PricingPlanPeriod;
  };
  queryOverage: {
    amount: number;
    price: PricingPlanPrice;
  };
  includedIndexing: {
    amount: number;
    period: PricingPlanPeriod;
  };
  indexingOverage: {
    amount: number;
    price: PricingPlanPrice;
  };
  includedStorage: number;
  storageOverage: {
    amount: number;
    price: PricingPlanPrice;
  };
  includedCorpora: number;
  corpusOverage: {
    amount: number;
    price: PricingPlanPrice;
  };
  queryStorageIngestBundle?: {
    ingestBytes?: number;
    period?: PricingPlanPeriod;
    price?: PricingPlanPrice;
    queries?: number;
    storageBytes?: number;
    summarizations?: number;
  };
  includedUsers: number;
  userOverage: {
    amount: number;
    price: PricingPlanPrice;
  };
  includedApiKeys: number;
  includedSummarizations: {
    amount: number;
    period: PricingPlanPeriod;
  };
  base: {
    amount: PricingPlanPrice;
    period: PricingPlanPeriod;
  };
  servingReplicas: number;
  maxRerankRows: number;
  maxResultRows: number;
  maxFilterAttrs: number;
  maxIndexedFilterAttrs: number;
  maxAttrsPerFilter: number;
  maxFilterSize: number;
  maxFilterLength: number;
};

export type Encoder = {
  name: string;
  description: string;
  cxSpec: string;
  cxSpecServing: string;
  dimension: number;
  default: boolean;
};

export type PromptType = "PLAIN" | "VELOCITY";

export type PromptObj = {
  type: PromptType;
  text: string;
};

export type PromptConfigParams = {
  frequency_penalty: number;
  max_tokens: number;
  presence_penalty: number;
  temperature: number;
  top_p: number;
};

export type Model = {
  modelId: number;
  modelName: string;
};

export type Prompt = {
  name: string;
  description: string;
  model: Model;
  isDefault: boolean;
  prompt: PromptObj;
  configParams: PromptConfigParams;
};

export type AWSMPCustomer = {
  customerId: number;
  name: string;
  userEmail: string;
  awsId: string;
  awsAccountId: string;
  tsContractStart: number;
  tsContractExpire: number;
  awsProductCode: string;
  planId: string;
  planName: string;
};

export type FailedDoc = {
  id: number;
  documentId: string;
  customerId: number;
  corpusId: number;
  originalStatusCode: number;
  originalStatusText: string;
  mimeType: string;
  locationType: string;
  locationPath: string;
  locationBucket: string;
  api: string;
};

export type FaissServer = {
  id: number;
  host: string;
  labels: string;
  capacityGB: number;
  reservedGB: number;
  availableGB: number;
  customersCount: number;
};

export type ScalePlanDefaults = {
  maxCorpora: number;
  maxResultRows: number;
  maxRerankRows: number;
  maxBytes: number;
};

export type PricingPlanDetails = {
  planId: string;
  name: string;
  planSpec: any;
};

export type Field = {
  value: string;
  isValid: boolean;
  error?: string;
};
