import "./Header.css";
import logo from "../images/vectara-colored-logo.png";
import { useEffect, useState } from "react";
import { useLoginContext } from "../utils/LoginContext";
import { useNavigate } from "react-router-dom";
import { authorizeUser } from "../api/api";
import { removeAuthTokenFromStorage, setAuthTokenInStorage, setSessionInStorage } from "../utils/storage";
import { GoogleSsoButton } from "../auth/GoogleSsoButton";
import { refreshJwt } from "../auth/refreshJwt";
import { VuiButtonSecondary, VuiIcon, VuiModal } from "@vectara/vectara-ui";
import { BiExit, BiLogIn } from "react-icons/bi";
import { logoutFromOry } from "../auth/oryGoogleAuth";
import { LoginForm } from "../auth/LoginForm";
import { toast } from "react-toastify";

export const Header = () => {
  const { setAuthToken, setAuthUser, isAuthenticated, setIsAuthenticated } = useLoginContext();
  const navigate = useNavigate();

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  // Default to true if the environment variable is not set
  const isSsoEnabled = process.env.REACT_APP_IS_SSO_ENABLED?.toLowerCase() !== "false";

  useEffect(() => {
    // Check for an active session when the component is mounted
    checkSession();
  }, []);

  const checkSession = async () => {
    const jwtToken = await refreshJwt();
    if (jwtToken) {
      attemptAuthorization(jwtToken);
    }
  };

  const attemptAuthorization = async (jwtToken: string) => {
    try {
      const authUser = await authorizeUser(jwtToken);
      if (authUser) {
        setIsAuthenticated(true);
        setAuthUser(authUser);
        setAuthToken(jwtToken);
        setAuthTokenInStorage(jwtToken);
      }
    } catch (e: unknown) {
      clearAuth();
      console.error(e);
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  };

  const doLogout = async () => {
    await logoutFromOry();
    clearAuth();
    navigate("/");
  };

  const clearAuth = () => {
    setIsAuthenticated(false);
    setAuthToken("");
    removeAuthTokenFromStorage();
    setSessionInStorage(null);
  };

  const renderLogin = () => {
    if (isAuthenticated) {
      return (
        <VuiButtonSecondary
          icon={
            <VuiIcon size="m">
              <BiExit />
            </VuiIcon>
          }
          color="neutral"
          fullWidth
          onClick={doLogout}
        >
          Log out
        </VuiButtonSecondary>
      );
    }
    if (!isSsoEnabled) {
      return (
        <VuiButtonSecondary
          icon={
            <VuiIcon size="m">
              <BiLogIn />
            </VuiIcon>
          }
          color="neutral"
          fullWidth
          onClick={() => setLoginModalOpen(true)}
        >
          Log in
        </VuiButtonSecondary>
      );
    } else {
      return <GoogleSsoButton />;
    }
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-purple-600 p-6 mb-5">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img onClick={() => navigate("/")} className="logo-image" alt="Search" width="54" height="54" src={logo}></img>
        <span className="font-semibold text-xl tracking-tight">Vectara Command & Control Center</span>
      </div>
      <div>{renderLogin()}</div>
      <VuiModal
        color="danger"
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        icon={
          <VuiIcon size="s">
            <img width={30} height={30} src={logo} alt="Vectara logo" />
          </VuiIcon>
        }
        title="Login"
      >
        <LoginForm setIsOpen={setLoginModalOpen} checkSession={checkSession} />
      </VuiModal>
    </nav>
  );
};
