// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  height: 5vh;
  border: solid black 3px;
  padding: 2;
}

.link {
  margin-left: 5px;
  padding-top: 5px;
  margin-top: 10px;
}

.logo-image {
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".header {\n  width: 100%;\n  height: 5vh;\n  border: solid black 3px;\n  padding: 2;\n}\n\n.link {\n  margin-left: 5px;\n  padding-top: 5px;\n  margin-top: 10px;\n}\n\n.logo-image {\n  vertical-align: middle;\n  margin-right: 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
