import { useEffect, useRef, useState } from "react";
import { VuiButtonSecondary } from "@vectara/vectara-ui";
import googleSsoLogo from "./google_sso_logo.png";
import { createOryLoginFlow } from "./oryGoogleAuth";

const ORY_SDK_URL = process.env.REACT_APP_ORY_SDK_URL;

export const GoogleSsoButton = () => {
  const [oryGoogleSsoFlowId, setOryGoogleSsoFlowId] = useState("");
  const [oryGoogleSsoLoginCsrfToken, setOryGoogleSsoLoginCsrfToken] = useState("");
  const googleFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const createGoogleLoginFlow = async () => {
      const { flowId, csrfToken } = await createOryLoginFlow();
      setOryGoogleSsoFlowId(flowId);
      setOryGoogleSsoLoginCsrfToken(csrfToken);
    };

    createGoogleLoginFlow();
  }, []);

  const label = "Sign in with Google";

  const ssoButton = (
    <VuiButtonSecondary
      icon={<img src={googleSsoLogo} width={18} alt="Google logo" />}
      color="neutral"
      fullWidth
      onClick={async () => {
        googleFormRef?.current?.submit();
      }}
    >
      {label}
    </VuiButtonSecondary>
  );

  return (
    <form action={`${ORY_SDK_URL}/self-service/login?flow=${oryGoogleSsoFlowId}`} method="POST" ref={googleFormRef}>
      <input type="hidden" name="provider" value="google" />
      <input type="hidden" name="csrf_token" value={oryGoogleSsoLoginCsrfToken} />
      {ssoButton}
    </form>
  );
};
