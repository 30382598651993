import { orySdk } from "./orySdk";
import { refreshJwt } from "./refreshJwt";

export const authenticateWithPassword = async () => {
  return await refreshJwt();
};

const createOryLoginFlow = async () => {
  try {
    // Sometimes the Ory session cookie lingers if the user logs out but closes
    // the tab before the logout process completes. Passing refresh: true will
    // prevent this lingering cookie from preventing login.
    // See https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-advanced-integration#refreshing-user-session
    const response = await orySdk.createBrowserLoginFlow({ refresh: true });

    if (!response.data) {
      throw new Error("Failed to create Login Flow");
    }

    if (response.data) {
      let csrf_token;
      const flowId = response.data.id;
      response.data.ui.nodes.forEach((v) => {
        const attr: any = v.attributes;
        if (attr.name === "csrf_token") {
          csrf_token = attr.value;
          return;
        }
      });

      return { flowId, csrfToken: csrf_token };
    }

    throw new Error("failed to create login flow");
  } catch (e) {
    console.log("createOryLoginFlow failed", e);
    throw e;
  }
};

const loginWithOry = async (email: string, password: string, customerId: number, flowId: string, csrfToken: string) => {
  try {
    const response = await orySdk.updateLoginFlow({
      flow: flowId,
      updateLoginFlowBody: {
        password: password,
        identifier: `map[customer_id:${customerId} username:${email}]`,
        method: "password",
        csrf_token: csrfToken
      }
    });

    if (response.data) {
      // Get jwt and set session info in localstorage
      await refreshJwt();
      // Only set the auth mechanism if the user's registration is complete.
      return { userId: response.data.session.identity?.id };
    }
  } catch (error: unknown) {
    if (typeof error === "object" && error !== null && "response" in error) {
      const typedError = error as {
        response?: {
          data: {
            ui?: {
              messages: { id: number; text: string; type: string }[];
            };
          };
        };
      };

      const errorMessages = typedError?.response?.data?.ui?.messages.filter((msg: any) => msg?.type === "error");

      if (errorMessages && errorMessages.length > 0) {
        console.error("loginWithOry failure", errorMessages[0].text, errorMessages[0].id);
        throw new Error(errorMessages[0].text);
      }
    }
    throw error;
  }
};

export const loginWithPassword = async ({
  password,
  email,
  customerId
}: {
  password: string;
  email: string;
  customerId?: string;
}) => {
  try {
    const customerIdNumeric = Number(customerId);
    const { flowId, csrfToken } = await createOryLoginFlow();

    if (!flowId || !csrfToken) {
      throw new Error(`failed to create login flow, missing flowId: ${csrfToken} or csrfToken: ${flowId}`);
    }

    const loginResponse = await loginWithOry(email.toLowerCase(), password, customerIdNumeric, flowId, csrfToken);

    return {
      customerId: customerIdNumeric.toFixed(0),
      userId: loginResponse?.userId
    };
  } catch (e) {
    console.log("loginWithPassword failed", e);
    throw e;
  }
};
