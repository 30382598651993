import { Customer } from "./commonTypes";

const C3SESSION = "C3SESSION";

export const getAuthTokenFromStorage = () => {
  return localStorage.getItem("AuthToken");
};

export const setAuthTokenInStorage = (token: string) => {
  localStorage.setItem("AuthToken", token);
};

export const removeAuthTokenFromStorage = () => {
  localStorage.removeItem("AuthToken");
};

export const getScaleIDs = () => {
  return localStorage.getItem("ScaleIDs");
};

export const setScaleIDs = (scaleIDs: string) => {
  localStorage.setItem("ScaleIDs", scaleIDs);
};

export const removeScaleIDs = () => {
  localStorage.removeItem("ScaleIDs");
};

export const setCustomerData = (customer: Customer) => {
  localStorage.setItem("currentCustomer", JSON.stringify(customer));
};

export const getCustomerData = () => {
  const customer = localStorage.getItem("currentCustomer");
  if (customer) {
    try {
      return JSON.parse(customer);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};

export const storeCustomerID = (customerId: string) => {
  localStorage.setItem("customerId", customerId.toString());
};

export const readCustomerID = () => {
  return localStorage.getItem("customerId");
};

export const storeUsername = (username: string) => {
  localStorage.setItem("username", username);
};

export const readUsername = () => {
  return localStorage.getItem("username");
};

export const getSessionFromStorage = (): {
  expiresAt: Date;
  jwt: string;
  email: string;
  username: string;
  sessionId: string;
  customerId: number;
  jwtExpiry: Date;
} | null => {
  const sessionStr = localStorage.getItem(C3SESSION);
  if (!sessionStr) {
    return null;
  }
  return JSON.parse(localStorage.getItem(C3SESSION) ?? "{}");
};

export const setSessionInStorage = (session: unknown) => {
  localStorage.setItem(C3SESSION, JSON.stringify(session));
};

export const removeSession = () => {
  localStorage.removeItem(C3SESSION);
};
