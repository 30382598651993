// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginPage {
  padding-left: 3%;
  padding-right: 3%;
}

.loginButton {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/auth/LoginForm.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":["\n.loginPage {\n  padding-left: 3%;\n  padding-right: 3%;\n}\n\n.loginButton {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
