import { VuiButtonSecondary, VuiFlexContainer, VuiFormGroup, VuiTextInput } from "@vectara/vectara-ui";
import { PasswordField } from "./PasswordField";
import { Field } from "../utils/commonTypes";
import { useEffect, useState } from "react";
import "./LoginForm.scss";
import { loginWithPassword } from "./oryPasswordAuth";
import { isNullOrWhitespace, validatePassword } from "../utils/commonFunctions";
import { toast } from "react-toastify";
import { readCustomerID, storeCustomerID, storeUsername } from "../utils/storage";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  checkSession: () => void;
};

export const LoginForm = ({ setIsOpen, checkSession }: Props) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState<Field>({ value: "", isValid: false });
  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    const storedCustomerId = readCustomerID();
    const storedUsername = localStorage.getItem("username");
    if (storedCustomerId) {
      setCustomerId(storedCustomerId);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const validateInput = () => {
    const isCustomerIdValid = customerId.length > 0 && customerId.length <= 11 && !isNaN(Number(customerId));
    if (!isCustomerIdValid) {
      toast.error("Invalid Customer ID");
      return false;
    }
    const isUsernameValid = !isNullOrWhitespace(username);
    if (!isUsernameValid) {
      toast.error("Invalid Username");
      return false;
    }
    const isPasswordValid = validatePassword(password.value);
    if (!isPasswordValid) {
      toast.error("Invalid Password");
      return false;
    }
    return true;
  };

  const onLogin = async () => {
    if (!validateInput()) {
      return;
    }
    setIsLoggingIn(true);
    try {
      await loginWithPassword({ password: password.value, email: username, customerId: customerId });
      checkSession();
      storeCustomerID(customerId);
      storeUsername(username);
    } catch (e) {
      console.error("login failed", e);
      toast.error("Login failed. Please check your credentials and try again.");
    } finally {
      setIsLoggingIn(false);
      setIsOpen(false);
    }
  };

  return (
    <VuiFlexContainer className="loginPage" direction="column">
      <VuiFormGroup label="Customer ID" labelFor="customerNumber" helpText="Enter the unique customer ID.">
        <VuiTextInput
          id="customerNumber"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          name="custNum"
          fullWidth
          autoComplete="custNum"
          onSubmit={onLogin}
        />
      </VuiFormGroup>

      <VuiFormGroup
        label="Username"
        labelFor="username"
        helpText="Check your invitation email to find the username that the account owner assigned to you."
      >
        <VuiTextInput
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          name="username"
          fullWidth
          autoComplete="username"
          onSubmit={onLogin}
        />
      </VuiFormGroup>

      <PasswordField
        id="password"
        name="password"
        label="Password"
        autoComplete="password"
        onSubmit={onLogin}
        password={password}
        onChange={(value) => setPassword({ value, isValid: value.length > 0 })}
        fullWidth
      />

      <VuiButtonSecondary color="accent" className="loginButton" onClick={() => onLogin()}>
        {isLoggingIn && <span>Signing in…</span>}
        {!isLoggingIn && <span>Sign in</span>}
      </VuiButtonSecondary>
    </VuiFlexContainer>
  );
};
