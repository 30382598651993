import { removeSession } from "../utils/storage";
import { orySdk } from "./orySdk";

export const createOryLoginFlow = async () => {
  // Sometimes the Ory session cookie lingers if the user logs out but closes
  // the tab before the logout process completes. Passing refresh: true will
  // prevent this lingering cookie from preventing login.
  // See https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-advanced-integration#refreshing-user-session
  const flow = await orySdk.createBrowserLoginFlow({ refresh: true });

  const csrfNode: any = flow.data.ui.nodes.find((node: any) => {
    return node.attributes.name === "csrf_token";
  });

  return { flowId: flow.data.id, csrfToken: csrfNode?.attributes.value };
};

export const logoutFromOry = async () => {
  // If logged in via Google SSO, this will automatically redirect.
  try {
    const { data: flow } = await orySdk.createBrowserLogoutFlow();

    await orySdk.updateLogoutFlow({
      token: flow.logout_token
    });

    removeSession();
    return;
  } catch (e) {
    console.log("logout failed", e);
    throw e;
  }
};
