// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-indicator {
  height: 3px;
  width: 0;
  margin: auto;
  margin-top: 4px;
  background-color: #215db0;
  border-radius: 2px;
  transition: width 300ms;
}

.active-tab-indicator {
  width: 100%;
}

.tabbedRouteContent {
  margin: 0 auto;
  max-width: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/components/tabbedRoutes/TabbedRoutes.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF","sourcesContent":[".tab-indicator {\n  height: 3px;\n  width: 0;\n  margin: auto;\n  margin-top: 4px;\n  background-color: #215db0;\n  border-radius: 2px;\n  transition: width 300ms;\n}\n\n.active-tab-indicator {\n  width: 100%;\n}\n\n.tabbedRouteContent {\n  margin: 0 auto;\n  max-width: 1000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
