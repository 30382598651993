import { jwtDecode } from "jwt-decode";
import { orySdk } from "./orySdk";
import { getSessionFromStorage, setSessionInStorage } from "../utils/storage";
import { handleErrors } from "../utils/errorHandler";

const refreshOryJwt = async () => {
  try {
    const tokenTemplateName = process.env.REACT_APP_ORY_JWT_TEMPLATE_NAME;

    const response = await orySdk.toSession({
      tokenizeAs: tokenTemplateName
    });

    const result = response.data;
    const jwtExpiry = jwtDecode(result?.tokenized ?? "")?.exp;

    setSessionInStorage({
      expiresAt: result?.expires_at,
      jwt: result?.tokenized,
      sessionId: result?.id,
      email: result.identity?.traits?.email,
      username: result.identity?.traits?.user_info?.username,
      customerId: result.identity?.traits?.user_info?.customer_id,
      jwtExpiry: jwtExpiry ? new Date(jwtExpiry * 1000) : null
    });

    return {
      id: result?.id,
      expiresAt: result?.expires_at,
      tokenized: result?.tokenized,
      customerId: result.identity?.traits?.user_info?.customer_id,
      username: result.identity?.traits?.user_info?.username,
      email: result.identity?.traits?.email,
      userId: result.identity?.id
    };
  } catch (e: any) {
    if (e.response.status !== 200) {
      setSessionInStorage(null);
      if (e.response.status === 401) {
        // Unauthorized
        handleErrors(e.response.status, "Unauthorized. Please log in again.");
      }
      return null;
    }
    console.log(e);
  }
};

export const refreshJwt = async () => {
  const session = getSessionFromStorage();
  const currentJwtExpirationTime = new Date(session?.jwtExpiry ?? "").getTime();
  const now = new Date().getTime();
  const timeUntilCurrentJwtExpiresMs = session?.jwtExpiry ? currentJwtExpirationTime - now : -1;
  const TWO_MINUTES_MS = 120000;

  if (timeUntilCurrentJwtExpiresMs >= TWO_MINUTES_MS) {
    // If current session's JWT will continue to be valid for more than 2 minutes, use it.
    return session?.jwt;
  } else {
    // If current session's JWT will expire in less than 2 minutes, refresh it and extend the session with the new token.
    const newJwt = await refreshOryJwt();
    return newJwt?.tokenized;
  }
};
